import React, { Component } from 'react';
import Features from './components/Features';
import NavMenu from './components/NavMenu';
import Plan from './components/Plan';
import './App.css';
// import Social from './components/Social';
import Footer from './components/Footer';
// import Monitoring from './components/Monitoring';
// import TESTIMONIALS from './components/Testimonials';
export class App extends Component {
	render() {
		return (
			<div>
				<div>
					<NavMenu />

					<Plan />
					<Features />
					{/*  END OF PRCING BOXES  */}
					{/*  TESTIMONIALS  */}
					{/* <TESTIMONIALS></TESTIMONIALS> */}
					{/*  END OF TESTIMONIALS */}
					{/*  MONITORING  */}
					{/* <Monitoring /> */}
					{/*  END OF MONITORING  */}
					{/*  FOOTER  */}
					<Footer />
					{/*  END OF FOOTER  */}
					<a href="/#top" id="back-to-top">
						<i className="fa fa-angle-up" />
					</a>
				</div>
			</div>
		);
	}
}

export default App;
