import React, { Component } from 'react';

export class Footer extends Component {
	render() {
		return (
			<div>
				<footer>
					<div className="row">
						<div className="small-12 columns">
							<div className="contacts">
								<div className="row">
									<div className="small-12 large-4 medium-4 columns">
										<i className="fa fa-map-marker" />
										Jawa Timur, Indonesia
									</div>
									{/* <div className="small-12 large-3 medium-3 columns">
										<i className="fa fa-mobile" />
										+1 299-670-9615
									</div> */}
									<div className="small-12 large-4 medium-4 columns">
										<a
											href="https://tawk.to/chat/61d4fd6db84f7301d3295fbe/1fojvrte1"
											target="_blank"
											rel="noopener noreferrer"
										>
											<i className="fa fa-comments" />
										</a>
										LIVE CHAT
									</div>
									<div className="small-12 large-4 medium-4 columns">
										<a href="mailto:support@ranuhosting.com">
											<i className="fa fa-envelope-o" />
										</a>
										E-MAIL US
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="row">
						<div className="small-12 columns">
							<div className="footerlinks"> */}
					{/* <div className="small-12 large-3 medium-3 columns border-right">
									<h2>Hosting Services</h2>
									<ul>
										<li>
											<a href="shared.html">Shared Hosting</a>
										</li>
										<li>
											<a href="vps.html">Managed VPS</a>
										</li>
										<li>
											<a href="servers.html">Dedicated Services</a>
										</li>
										<li>
											<a href>Become a Reseller</a>
										</li>
										<li>
											<a href>Special Offers</a>
										</li>
									</ul>
								</div> */}
					{/* <div className="small-12 large-3 medium-3 columns border-right">
									<h2>Company</h2>
									<ul>
										<li>
											<a href>About us</a>
										</li>
										<li>
											<a href>Blog</a>
										</li>
										<li>
											<a href>Terms of Service</a>
										</li>
										<li>
											<a href>Acceptable Use Policy</a>
										</li>
										<li>
											<a href>Affiliates</a>
										</li>
									</ul>
								</div> */}
					{/* <div className="small-12 large-3 medium-3 columns border-right">
									<h2>Add-on Services</h2>
									<ul>
										<li>
											<a href>SSL Certificates</a>
										</li>
										<li>
											<a href>Dedicated IPs</a>
										</li>
										<li>
											<a href>Control Panel Licenses</a>
										</li>
										<li>
											<a href>WHMCS License</a>
										</li>
										<li>
											<a href>Migrations / Transfers</a>
										</li>
									</ul>
								</div> */}
					{/* Begin MailChimp Signup Form */}
					{/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}

					{/* <div className="small-12 large-3 medium-3 columns">
									<h2>CloudMe Newsletter</h2>
									<p>Sign up for special offers:</p>
									
									<div id="mc_embed_signup">
										<form
											action="//audemedia.us7.list-manage.com/subscribe/post?u=b5638e105dac814ad84960d90&id=9345afa0aa"
											method="post"
											id="mc-embedded-subscribe-form"
											name="mc-embedded-subscribe-form"
											className="validate"
											target="_blank"
											noValidate
										>
											<input
												type="email"
												defaultValue
												name="EMAIL"
												className="email"
												id="mce-EMAIL"
												placeholder="email address"
												required
											/>
											<div style={{ position: 'absolute', left: '-5000px' }}>
												<input
													type="text"
													name="b_b5638e105dac814ad84960d90_9345afa0aa"
													tabIndex={-1}
													defaultValue
												/>
											</div>
											<div className="clear">
												<input
													type="submit"
													defaultValue="Subscribe"
													name="subscribe"
													id="mc-embedded-subscribe"
													className="button"
												/>
											</div>
										</form>
									</div>
								</div> */}
					{/*End mc_embed_signup*/}
					{/* </div>
						</div>
					</div> */}
					{/*SOCIAL LINKS */}
					{/* <Social /> */}
					{/* END OF SOCIAL LINKS */}
					<p className="copyright">
						© Copyright 2021 <a href="https://ranuhosting.com/">Ranu Hosting</a> , all rights reserved.{' '}
					</p>
				</footer>
			</div>
		);
	}
}

export default Footer;
