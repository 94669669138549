import React, { Component } from 'react';

export class Features extends Component {
	render() {
		return (
			<section className="features">
				<div className="row">
					<div className="small-12 columns">
						<h2>Fitur Hosting</h2>
						<p>
							Fitur-fitur ini menjadi standar di semua paket hosting kami. Secara dinamis memperluas
							imperatif melalui sumber terbuka dengan cepat ceruk pasar.
						</p>
						<ul className="small-block-grid-1 large-block-grid-3 medium-block-grid-3">
							<li data-wow-delay="0.2s" className="wow fadeInLeft">
								<i className="fa fa-database" />
								<h3>Cloud Databases</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
							<li data-wow-delay="0.4s" className="wow fadeInLeft">
								<i className="fa fa-git-square" />
								<h3>Pre-installed git</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
							<li data-wow-delay="0.6s" className="wow fadeInLeft">
								<i className="fa fa-code" />
								<h3>Developer-friendly tools</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
							<li data-wow-delay="0.2s" className="wow fadeInRight">
								<i className="fa fa-bolt" />
								<h3>Gigabit uplinks</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
							<li data-wow-delay="0.4s" className="wow fadeInRight">
								<i className="fa fa-hdd-o" />
								<h3>SSD-Only</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
							<li data-wow-delay="0.6s" className="wow fadeInRight">
								<i className="fa fa-rocket" />
								<h3>Running at scale</h3>
								{/* <p>
									Food truck artisan cillum voluptate umami Austin quis, viral asymmetrical freegan
									occaecat swag skateboard
								</p> */}
							</li>
						</ul>
					</div>
				</div>
			</section>
		);
	}
}

export default Features;
