import React, { Component } from 'react';

export class Plan extends Component {
	state = {
		data: [
			{
				title: 'Basic',
				ds: '2 GB',
				cpu: '1 Core',
				ram: '1 GB',
				aod: '1',
				ssh: 'No',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'RP 50.000',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			},
			{
				title: 'Starter',
				ds: '5 GB',
				cpu: '2 Core',
				ram: '2 GB',
				aod: '3',
				ssh: 'No',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'RP 120.000',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			},
			{
				title: 'Marketer',
				ds: '15 GB',
				cpu: '2 Core',
				ram: '2 GB',
				aod: 'Unlimited',
				ssh: 'Yes',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'RP 250.000',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			},
			{
				title: 'Business',
				ds: '25 GB',
				cpu: '3 Core',
				ram: '3 GB',
				aod: 'Unlimited',
				ssh: 'Yes',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'RP 300.000',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			},
			{
				title: 'Enterprise',
				ds: '50 GB',
				cpu: '4 Core',
				ram: '4 GB',
				aod: 'Unlimited',
				ssh: 'Yes',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'RP 500.000',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			},
			{
				title: 'Customisasi',
				ds: 'Customisasi',
				cpu: 'Customisasi',
				ram: 'Customisasi',
				aod: 'Unlimited',
				ssh: 'Yes',
				bandwidth: 'Yes',
				backup: 'Yes',
				harga: 'Chat Admin',
				linkbuy: 'https://my.ranuhosting.com/store/hosting'
			}
		]
	};
	DataMenu() {
		return this.state.data.map((row, key) => {
			return (
				<div data-wow-delay="0.2s" className="small-12 large-4 medium-4 columns wow zoomIn">
					<ul className="pricing-table">
						<li className="description">
							<h1 className="text-white textTitle"> {row.title} </h1>
						</li>
						{/* <li className="bullet-item">Free Domain No</li> */}
						<li className="bullet-item">
							<strong>SDD Disk Space</strong> {row.ds}
						</li>
						<li className="bullet-item">
							<strong>CPU</strong> {row.cpu}
						</li>
						<li className="bullet-item">
							<strong>RAM</strong> {row.ram}
						</li>
						<li className="bullet-item">
							<strong>Add Ons Domain</strong> {row.aod}
						</li>
						<li className="bullet-item">
							<strong>Akses SSH</strong> {row.ssh}
						</li>
						<li className="bullet-item">
							<strong>Unlimited Bandwidth</strong> {row.bandwidth}
						</li>
						{/* <li className="bullet-item">
							<strong>Free Weekly Backup</strong> {row.backup}
						</li> */}
						<li className="bullet-item">
							<strong>Free SSL</strong> Yes
						</li>
						<li className="price">
							<span>{row.harga} </span>BULAN
						</li>
						<li className="cta-button">
							<p>
								<span>
									<a href={row.linkbuy}>Pesan</a>
								</span>
							</p>
						</li>
					</ul>
				</div>
			);
		});
	}
	render() {
		return (
			<div>
				<section className="bg-cok">
					{/*  END OF FEATURES */}
					{/*  CALL TO ACTION  */}
					{/* <section class="calltoaction">
<div class="row">
<div class="small-12 columns">
<div data-wow-delay="0.3s" class="longshadow wow fadeInDown">NEW CUSTOMER?</div>
<div data-wow-delay="0.5s" class="calltoactioninfo wow fadeInUp">
<h2><span id="discount">0</span><span>%</span> OFF YOUR ORDER</h2>
<h3>USE PROMO CODE <strong>"CLOUDMENEW"</strong></h3>
<a href="#" class="small radius button">SIGN UP NOW!</a>
</div>
</div>
</div>
</section> */}
					{/*  END OF CALL TO ACTION */}
					{/*  PRICING BOXES  */}
					<div className="pricingboxes">
						<span id="pricingboxes" />
						<div className="row">
							<div className="small-12 columns">
								{/* <h2>FIND A PLAN THAT'S RIGHT FOR YOU</h2> */}
								<h2>Dapatkan Scalable Hosting Solution</h2>
								<p>
									Dapatkan Performa Tertinggi Dengan Super Hosting Cloud Hosting dengan Processor AMD
									EPYC 7282 16-Core Processor , RAM DDR4 dan SSD Enterprise Datacenter class membuat
									website Anda 5x lebih cepat! dan bukan hanya itu, Anda dapat dengan mudah mengatur
									resource hosting sesuai dengan kebutuhan. Buruan order sekarang juga! <br /> Gratis
									SSL selamanya serta bebas resiko
								</p>
							</div>
						</div>
						<div className="spacing-30" />
						<div className="row">
							<div className="title">SHARED HOSTING</div>
							{/* <div data-wow-delay="0.2s" className="small-12 large-4 medium-4 columns wow zoomIn"> */}
							{/* Basic */}
							{/* Starter */}
							{/* Marketer */}
							{/* Business */}
							{/* Unlimited */}
							{this.DataMenu()}

							{/* <div data-wow-delay="0.4s"  class="small-12 large-4 medium-4 columns wow zoomIn">
<div class="title">CLOUD VPS</div>
<ul class="pricing-table">
<li class="description">Deploy In <strong>30 Seconds</strong></li>
<li class="bullet-item">1GB storage</li>
<li class="bullet-item">3GB bandwidth</li>
<li class="bullet-item">Free Email Addresses</li>
<li class="bullet-item">24/7 security monitoring</li>
<li class="bullet-item">24/7 technical support</li>
<li class="price"><span>$79</span>MONTHLY</li>
<li class="cta-button"><p><span><a href="">SIGN UP NOW</a></span></p></li>
</ul>
</div>

<div data-wow-delay="0.6s"  class="small-12 large-4 medium-4 columns wow zoomIn">
<div class="title">DEDICATED SERVERS</div>
<ul class="pricing-table">
<li class="description">Comprehensive <strong>SLAs</strong></li>
<li class="bullet-item">1GB storage</li>
<li class="bullet-item">3GB bandwidth</li>
<li class="bullet-item">Free Email Addresses</li>
<li class="bullet-item">24/7 security monitoring</li>
<li class="bullet-item">24/7 technical support</li>
<li class="price"><span>$199</span>MONTHLY</li>
<li class="cta-button"><p><span><a href="">SIGN UP NOW</a></span></p></li>
</ul>
</div> */}
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Plan;
