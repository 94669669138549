import React, { Component } from 'react';

export class NavMenu extends Component {
	render() {
		return (
			<header>
				{/*  MESSAGES ABOVE HEADER IMAGE */}
				<div className="message">
					<div className="row">
						<div className="small-12 columns">
							<div className="message-intro">
								<span className="message-line" />
								<p>Ranu Hosting</p>
								<span className="message-line" />
							</div>
							<h1 className="txt-white">
								Hosting Super Dengan Komputasi <br /> Khusus & Performa Tinggi
								{/* <span id="js-rotating">
									HIGH PERFORMANCE VIRTUAL MACHINES, CLOUD FOR WEBSITES AND APPLICATIONS, INSTANT
									ACCOUNT ACTIVATION, EXCELLENT CPU AND IO PERFORMANCE, THE REAL PUBLIC CLOUD
									EXPERIENCE
								</span> */}
							</h1>
							<a href="/#pricingboxes" className="small radius button">
								VIEW PLANS
							</a>
						</div>
					</div>
				</div>
				{/*  END OF MESSAGES ABOVE HEADER IMAGE */}
				<div className="top">
					<div className="row">
						<div className="small-12 large-3 medium-3 columns">
							<div className="logo">
								<a href="/" title>
									<img src="images/logo.png" alt="Ranu Hosting" title="Ranu Hosting" />
								</a>
							</div>
						</div>
						<div className="small-12 large-9 medium-9 columns">
							<nav className="desktop-menu">
								<ul className="sf-menu">
									<li className="current-menu-item">
										<a href="/">HOME</a>
									</li>
									{/* <li>
										<a href="#">HOSTING</a>
										<ul>
											<li>
												<a href="shared.html">SHARED HOSTING</a>
											</li>
											<li>
												<a href="vps.html">CLOUD VPS</a>
											</li>
											<li>
												<a href="servers.html">DEDICATED SERVERS</a>
											</li>
										</ul>
									</li> */}
									<li>
										<a href="https://my.ranuhosting.com/cart.php?a=add&domain=register">DOMAINS</a>
									</li>
									<li>
										<a href="https://my.ranuhosting.com/index.php/login">Login</a>
									</li>
									{/* <li>
										<a href="#">PAGES</a>
										<ul>
											<li>
												<a href="support.html">SUPPORT</a>
											</li>
											<li>
												<a href="login.html">LOGIN</a>
											</li>
											<li>
												<a href="testimonials.html">TESTIMONIALS</a>
											</li>
											<li>
												<a href="typography.html">TYPOGRAPHY</a>
											</li>
										</ul>
									</li> */}
									{/* <li>
										<a href="#">BLOG</a>
										<ul>
											<li>
												<a href="blog.html">CATEGORY</a>
											</li>
											<li>
												<a href="blog-single.html">SINGLE POST</a>
											</li>
										</ul>
									</li> */}
									{/* <li>
										<a href="contact.html">CONTACT</a>
									</li> */}
								</ul>
							</nav>
							<nav className="mobile-menu">
								<ul>
									<li>
										<a href="/">HOME</a>
									</li>
									{/* <li>
										<a href="#">HOSTING</a>
										<ul>
											<li>
												<a href="shared.html">SHARED HOSTING</a>
											</li>
											<li>
												<a href="vps.html">CLOUD VPS</a>
											</li>
											<li>
												<a href="servers.html">DEDICATED SERVERS</a>
											</li>
										</ul>
									</li> */}
									<li>
										<a href="https://my.ranuhosting.com/cart.php?a=add&domain=register">DOMAINS</a>
									</li>
									<li>
										<a href="https://my.ranuhosting.com/login">Login</a>
									</li>
									{/* <li>
										<a href="#">PAGES</a>
										<ul>
											<li>
												<a href="support.html">SUPPORT</a>
											</li>
											<li>
												<a href="login.html">LOGIN</a>
											</li>
											<li>
												<a href="testimonials.html">TESTIMONIALS</a>
											</li>
											<li>
												<a href="typography.html">TYPOGRAPHY</a>
											</li>
										</ul>
									</li> */}
									{/* <li>
										<a href="#">BLOG</a>
										<ul>
											<li>
												<a href="blog.html">CATEGORY</a>
											</li>
											<li>
												<a href="blog-single.html">SINGLE POST</a>
											</li>
										</ul>
									</li> */}
									{/* <li>
										<a href="contact.html">CONTACT</a>
									</li> */}
								</ul>
							</nav>
							{/*  END OF MOBILE MENU AREA */}
						</div>
					</div>
				</div>
			</header>
		);
	}
}

export default NavMenu;
